:root {
  font-family: "Unica One", cursive !important;
  font-weight: 400 !important;
}
body {
  background: black;
  margin: 0;
  font-family: "Unica One", cursive !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  color: #f4f4f4;
  font-size: 62.5%;
  height: 100%;
  #root,
  .App {
    height: 100%;
  }
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
//     monospace;
// }
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s;
  -webkit-text-fill-color: #fff !important;
}

.countdown-timer {
  font-size: 48px;
  display: block;
  text-align: center;
}

// Modal animation
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
