$colorMainText: #f4f4f4;
$breakpointXs: 0px;
$breakpointSm: 600px;
$breakpointMd: 960px;
$breakpointLg: 1280px;
$breakpointXl: 1920px;
@keyframes grain {
  0% {
    -webkit-transform: translate(20%, -15%);
    transform: translate(20%, -15%);
  }
  10% {
    -webkit-transform: translate(-20%, -15%);
    transform: translate(-20%, -15%);
  }
  20% {
    -webkit-transform: translate(20%, -5%);
    transform: translate(20%, -5%);
  }
  30% {
    -webkit-transform: translate(-20%, -5%);
    transform: translate(-20%, -5%);
  }
  40% {
    -webkit-transform: translate(20%, 5%);
    transform: translate(20%, 5%);
  }
  50% {
    -webkit-transform: translate(-20%, 5%);
    transform: translate(-20%, 5%);
  }
  60% {
    -webkit-transform: translate(20%, 15%);
    transform: translate(20%, 15%);
  }
  70% {
    -webkit-transform: translate(-20%, 15%);
    transform: translate(-20%, 15%);
  }
  80% {
    -webkit-transform: translate(20%, 5%);
    transform: translate(20%, 5%);
  }
  90% {
    -webkit-transform: translate(-20%, 5%);
    transform: translate(-20%, 5%);
  }
  to {
    -webkit-transform: translate(20%, -5%);
    transform: translate(20%, -5%);
  }
}
.mobileBtn {
  @media (max-width: $breakpointSm) {
    width: 100%;
    display: block;
  }
}
.grain {
  background-image: url("./img/grain.png");
  background-repeat: repeat;
  animation: grain 6s steps(10) infinite;
  height: 300%;
  left: -100%;
  opacity: 0.07;
  pointer-events: none;
  position: fixed;
  top: -100%;
  width: 300%;
  will-change: transform;
  z-index: -1;
}

h1 {
  font-size: 64px;
  line-height: 76px;
  letter-spacing: 0.2em;
  margin-bottom: 20px;
  margin-top: 0;
  color: $colorMainText;
  font-weight: 400;

  @media (max-width: $breakpointMd) {
    font-size: 44px;
    line-height: 52px;
  }
}
h2 {
  font-size: 54px;
  line-height: 64px;
  letter-spacing: 0.2em;
  color: $colorMainText;
  margin: 0;
  font-weight: 400;
  @media (max-width: $breakpointMd) {
    font-size: 32px;
    line-height: 44px;
  }
}
h3 {
  font-size: 26px;
  line-height: 31px;
  letter-spacing: 0.2em;
  color: $colorMainText;
  font-weight: 400;
  @media (max-width: $breakpointMd) {
    font-size: 24px;
    line-height: 28px;
  }
}
h4 {
  font-size: 24px;
  line-height: 34px;
  color: $colorMainText;
  margin: 0;
  font-weight: 400;
  @media (max-width: $breakpointMd) {
    font-size: 20px;
    line-height: 34px;
  }
}
h5 {
  font-size: 18px;
  line-height: 28px;
  color: $colorMainText;
  margin: 0;
  font-weight: 400;
}

.planet-image-animation {
  animation: jupiter-rotating 20s linear infinite;
}

.planet  {
  transition: all 300ms ease-out;
}

.planet:hover {
  transform: scale(1.1);
  transition: all 300ms ease-in-out;
}

@keyframes jupiter-rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

::-webkit-scrollbar {
  width: 5px;
  background: #2c2b2bf0;
}

::-webkit-scrollbar-track {
  background: #767678ab;
}

::-webkit-scrollbar-thumb {
  background: #2a2727;
}

.btn {
  background-image: linear-gradient(90deg, transparent 50%, white 50%);
  position: relative;
  background-size: 300%;
  transition: all 1s;
}

.btn:hover {
  color: #000;
  background-position: 100%;
}

#bars {
  height: 20px;
  position: relative;
  width: 40px;
  margin-left: 10px;
}

.bar {
  background: #8b8b8b;
  bottom: 1px;
  height: 3px;
  position: absolute;
  width: 2px;
  border-radius: 10px;
  animation: sound 0ms -800ms linear infinite alternate;
}

.bar:nth-child(1) {
  left: 1px;
  animation-duration: 700ms;
}
.bar:nth-child(2) {
  left: 6px;
  animation-duration: 629ms;
}
.bar:nth-child(3) {
  left: 11px;
  animation-duration: 650ms;
}
.bar:nth-child(4) {
  left: 16px;
  animation-duration: 610ms;
}
.bar:nth-child(5) {
  left: 21px;
  animation-duration: 692ms;
}
.bar:nth-child(6) {
  left: 26px;
  animation-duration: 637ms;
}
@keyframes sound {
  0% {
    height: 3px;
  }
  100% {
    height: 20px;
  }
}
